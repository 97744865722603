import { crewlinq } from 'database';
import { eCrewlinqRights, eSendToWhom, iUser } from 'datamodel';
import { Timestamp } from 'firebase/firestore';
import i18n from 'i18next';
import moment, { Moment } from 'moment';
import store from 'rdx/store';

export const getBrowserLocales = (options = {}): string[] | undefined => {
  const defaultOptions = {
    languageCodeOnly: false,
  };
  const opt = {
    ...defaultOptions,
    ...options,
  };
  const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;
  if (!browserLocales) return undefined;
  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();
    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
};

export const daysOfWeek = () => moment.weekdays(true);

export const translateOfferedTo = (whom: eSendToWhom | null | undefined): string => {
  // console.log('What is whom?', whom);
  if (whom === 'departmentList') return 'list-of-departments';
  if (whom === 'onQualifications') return 'list-of-qualifications';
  if (whom === 'ownDepartment') return 'own-department';
  if (whom === 'externalList') return 'external-list';
  if (whom === null || whom === undefined) return '';
  return 'unknown-option';
  // {t('list-of-departments')}
  // {t('list-of-qualifications')}
  // {t('own-department-0')}
  // {t('external-list')}
};

/**
 *
 * @param array of string
 * @param sorted boolean default false
 * @param separator string default ', '
 * @returns string with comma separated items
 */
export const arrayToString = (array: string[], sorted = false, separator = ', '): string => {
  const result: string[] = [];
  array.forEach((item) => {
    result.push(item);
  });
  if (sorted) result.sort((a, b) => a.localeCompare(b));
  return result.join(separator);
};

/**
 *
 * @param str String to make TitleCase
 * @returns TitleCase string
 */
export const makeTitleCase = (str: string): string =>
  str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());

/**
 *
 * @param searchString any string to be made into search array
 * @returns string[] array of search terms (uppercase, lowercase, CamelCase)
 */
export const makeSearchArray = (searchString: string): string[] => {
  let resetName = searchString.toLowerCase();
  resetName = resetName.charAt(0).toUpperCase() + resetName.slice(1);
  const searchFor = [
    searchString,
    searchString.toLowerCase(),
    searchString.toUpperCase(),
    searchString.charAt(0).toUpperCase() + searchString.slice(1),
    makeTitleCase(searchString),
    resetName,
  ];
  return searchFor;
};

/**
 *
 * @param num Number or string to make double digit
 * @returns string of at least double digit
 */
export const toDoubleDigit = (num: number | string): string => {
  if (num.toString().length < 2) return `0${num}`;
  return num.toString();
};

export const makeRandomString = (length: number): string => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

export const makeRandomEasyReadString = (length: number): string => {
  const chars = '23456789abcdefghijkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

const formatDate = (date: string | Moment | Timestamp | Date, format: string): string => {
  if (typeof date === 'string') return moment(date).format(format);
  if (date instanceof Timestamp) return moment(date.toDate()).format(format);
  if (date instanceof Date) return moment(date).format(format);
  if (date instanceof moment) return date.format(format);
  if (typeof date === 'object') {
    // The provided date is a Firestore Timestamp but not as the firestore Timestamp type
    const x = date as never as { seconds: number; nanoseconds: number };
    const d = new Date(x.seconds * 1000);
    return moment(d).format(format);
  }
  try {
    const d = moment(date).format(format);
    return d;
  } catch (error) {
    console.log('formatDate error', error);
  }
  return '';
};

export const dateToDot = (date: string | Moment | Timestamp | Date | undefined | null): string => {
  if (date === undefined || date === null) return '';
  return formatDate(date, 'DD.MM.YYYY');
};

export const dateToShort = (date: string | Moment | Timestamp | Date | undefined | null): string => {
  if (date === undefined || date === null) return '';
  return formatDate(date, 'YYYYMMDD');
};

export const getArrayNextItem = (array: string[], currentItem: string): string => {
  const index = array.indexOf(currentItem);
  if (index === -1) return '';
  if (index === array.length - 1) return array[0];
  return array[index + 1];
};

export const tsOrDateToMoment = (timestamp: Timestamp | Date | string): Moment => {
  if (timestamp instanceof Timestamp) return moment(timestamp.toDate());
  if (timestamp instanceof Date) return moment(timestamp);
  if (typeof timestamp === 'string') return moment(timestamp);
  return moment();
};

export const getEnvironment = (): 'localDev' | 'development' | 'demo' | 'production' => {
  if (process.env.NODE_ENV === 'development') return 'localDev';
  if (['localhost', '127.0.0.1', '0.0.0.0'].includes(window.location.hostname)) return 'localDev';
  if (['crewlinq-dev.web.app', 'crewlinq-dev.firebaseapp.com'].includes(window.location.hostname)) return 'development';
  if (
    ['demo.crewlinq.com', 'crewlinq-demo.web.app', 'crewlinq-demo.firebaseapp.com'].includes(window.location.hostname)
  )
    return 'demo';
  if (['portal.crewlinq.com', 'crewlinq.firebaseapp.com', 'app.crewlinq.com'].includes(window.location.hostname))
    return 'production';
  return 'development';
};

export const hasRight = (user: iUser | null, rights: eCrewlinqRights[] | eCrewlinqRights): boolean => {
  if (!user) return false;
  if (!user.rights) return false;
  if (!Array.isArray(rights)) return user.rights.includes(rights);
  for (let i = 0; i < rights.length; i += 1) {
    if (user.rights.includes(rights[i])) return true;
  }
  return false;
};

export const rightsList = () => {
  const localStore = store.getState();
  const hasVolunteers = localStore.appState.house?.settings?.volunteerOffers?.enabled;
  const list = Object.values(eCrewlinqRights)
    .filter((r) => r !== eCrewlinqRights.User)
    .map((r) => {
      return { value: r, label: i18n.t(`crewlinqRights.${r}`) };
    });
  // if not hasVolunteers, remove Volunteer
  if (!hasVolunteers) {
    const index = list.findIndex((r) => r.value === eCrewlinqRights.VolunteerDepartmentAdmin);
    if (index !== -1) list.splice(index, 1);
    const index2 = list.findIndex((r) => r.value === eCrewlinqRights.VolunteerGlobalAdmin);
    if (index2 !== -1) list.splice(index2, 1);
  }
  return list;
};

export const replaceGermanChars = (text: string): string => {
  return text
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss')
    .replace(/Ä/g, 'Ae')
    .replace(/Ö/g, 'Oe')
    .replace(/Ü/g, 'Ue')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

export const trimTo = (str: string | string[] | number | React.ReactNode, length: number) => {
  if (typeof str === 'number') return str;
  if (Array.isArray(str)) return str.map((s) => (s.length > length ? `${s.substring(0, length - 3)}...` : s));
  if (typeof str === 'string') return str.length > length ? `${str.substring(0, length - 3)}...` : str;
  return '';
};

export const userHasApiConnection = async (userId: string): Promise<false | string> => {
  const localStore = store.getState();
  if (!localStore.appState.house?.addOns.API) return false;
  const user = await crewlinq.getAUserDoc(userId);
  if (!user) return false;
  if (user.apiData) return user.extId || false;
  return false;
};
