import { checkTenantId, checkUserId } from 'database';
import { eLeasingRequestOfferStatus, eLeasingRequestStatus, iLeasingRequest, iLeasingRequestLog } from 'datamodel';
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  Query,
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from 'localFirebase';
import moment, { Moment } from 'moment';

const debug = false;

export const getRequestsRef = (): CollectionReference<DocumentData> | false => {
  if (debug) console.log('getRequestsRef');
  const offers = collection(db, 'leasingOffers');
  return offers;
};

export const getARequestRef = (id: string): DocumentReference<DocumentData> | false => {
  if (debug) console.log('getARequestRef');
  const offersRef = getRequestsRef();
  if (!offersRef) return false;
  const offerRef = doc(offersRef, id);
  return offerRef;
};

export const getFirstRequestFromOfferRef = async (
  commonId: string,
  whenString: string,
): Promise<DocumentReference<DocumentData> | false> => {
  if (debug) console.log('getFirstRequestFromOfferRef');
  const offersRef = getRequestsRef();
  if (!offersRef) return false;
  if (!commonId || !whenString) return false;
  const q = query(offersRef, where('commonId', '==', commonId), where('whenString', '==', whenString));
  const snap = await getDocs(q);
  if (snap.empty) return false;
  return snap.docs[0].ref;
};

export const addRequestLog = async (
  id: string,
  message: string,
  status: eLeasingRequestStatus | eLeasingRequestOfferStatus,
): Promise<boolean> => {
  if (debug) console.log('addRequestLog');
  const offersRef = getRequestsRef();
  if (!offersRef) return false;
  const logRef = collection(offersRef, id, 'logs');
  const userId = checkUserId();
  console.time('addRequestLogAction');
  await addDoc(logRef, {
    message,
    status,
    when: Timestamp.now(),
    user: userId,
    tenant: checkTenantId(),
  });
  console.timeEnd('addRequestLogAction');
  return true;
};

export const getRequestLogs = async (
  id: string,
  idOfRequestCustomer: string,
): Promise<iLeasingRequestLog[] | false> => {
  if (debug) console.log('getRequestLogs');
  const requestRef = getRequestsRef();
  if (!requestRef) return false;
  const logs: iLeasingRequestLog[] = [];
  const logsRef = collection(requestRef, id, 'logs');
  const tenantIn = [checkTenantId(), idOfRequestCustomer];
  const q = query(logsRef, where('tenant', 'in', tenantIn), orderBy('when', 'asc'));
  const qNewCustomer = query(
    logsRef,
    where('leasingCompanyId', 'in', [checkTenantId(), 'all']),
    orderBy('when', 'asc'),
  );
  const logsSnap = await getDocs(q);
  const logsSnapNew = await getDocs(qNewCustomer);
  logsSnap.forEach((log) => {
    logs.push({ id: log.id, ...log.data() } as iLeasingRequestLog);
  });
  logsSnapNew.forEach((log) => {
    logs.push({ id: log.id, ...log.data() } as iLeasingRequestLog);
  });
  logs.sort((a, b) => {
    return (a.when as Timestamp).seconds - (b.when as Timestamp).seconds;
  });
  return logs;
};

export const getRequestsFromCustomerRef = (): Query<DocumentData> | false => {
  if (debug) console.log('getRequestsFromCustomerRef');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const offers = collection(db, 'leasingOffers');
  const q = query(
    offers,
    where('houseId', '==', tenantId),
    where('customerDone', '==', false),
    orderBy('whenString', 'asc'),
  );
  return q;
};

/**
 *
 * @param filterDone if true, only return requests that are not done
 * @returns Query<DocumentData> | false
 */
export const getRequestsFromOwnerRef = (filterDone = true): Query<DocumentData> | false => {
  if (debug) console.log('getRequestsFromOwnerRef');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const offers = collection(db, 'leasingOffers');
  let q: Query<DocumentData>;
  if (filterDone) {
    q = query(
      offers,
      where('leasingCompanyIds', 'array-contains', tenantId),
      where('leasingDone', '==', false),
      orderBy('whenString', 'asc'),
    );
  } else {
    q = query(offers, where('leasingCompanyIds', 'array-contains', tenantId), orderBy('whenString', 'asc'));
  }
  return q;
};

export const getUnresolvedRequestsRef = (): Query<DocumentData> | false => {
  if (debug) console.log('getUnresolvedRequestsRef');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const offers = collection(db, 'leasingOffers');
  const q = query(
    offers,
    where('leasingCompanyIdsUnresolvedStatus', 'array-contains', tenantId),
    orderBy('whenString', 'asc'),
  );
  return q;
};

export const getRequestRangeFromOwnerRef = (start: string, end: string): Query<DocumentData> | false => {
  if (debug) console.log('getRequestRangeFromOwnerRef');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const offers = collection(db, 'leasingOffers');
  const q = query(
    offers,
    where('leasingCompanyIds', 'array-contains', tenantId),
    where('whenString', '>=', start),
    where('whenString', '<=', end),
    orderBy('whenString', 'asc'),
  );
  return q;
};

export const updateRequestById = async (id: string, value: Partial<iLeasingRequest>): Promise<boolean> => {
  const req = httpsCallable(functions, 'updateLeasingRequest');
  const res = await req({ request: value, requestId: id });
  console.log('res', res);
  // const employeeId = checkUserId();
  // const tenantId = checkTenantId();
  // if (debug) console.log('updateRequestById');
  // console.time('updateRequestById');
  // const offersRef = getRequestsRef();
  // if (!offersRef) return false;
  // const logRef = collection(offersRef, id, 'logs');
  // if (!logRef) return false;
  // const logItems: iLeasingRequestLog[] = [];
  // if (value.leasingCompanies) {
  //   Object.keys(value.leasingCompanies).forEach((key) => {
  //     const originalValue = original.leasingCompanies[key];
  //     const newValue = value.leasingCompanies[key];
  //   }
  // });

  // console.time('updateDoc');
  // const promise = [
  //   updateDoc(doc(offersRef, id), { ...value, updatedAt: Timestamp.now(), updatedBy: checkUserId() }),
  //   // addDoc(logRef, {
  //   //   employeeId,)
  // ];

  // console.timeEnd('updateDoc');
  // console.time('addRequestLog');
  // await Promise.all(promise);
  // console.timeEnd('addRequestLog');
  // console.timeEnd('updateRequestById');
  return true;
};

export const getRequestsForOneEmployee = (employeeId: string): Query<DocumentData> | false => {
  if (debug) console.log('getRequestsForOneEmployee');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const requests = collection(db, 'leasingOffers');
  const q = query(
    requests,
    // where('houseId', '==', tenantId),
    where('leasingCompanyIds', 'array-contains', tenantId),
    where(`leasingCompanies.${tenantId}.offeredEmployee.id`, '==', employeeId),
    where('whenString', '>=', moment().format('YYYYMMDD')),
    // where(`leasingCompanies.${tenantId}.status`, '==', 'accepted'),
    orderBy('whenString', 'asc'),
  );
  return q;
};

export const getRelatedRequests = (hid: string, houseId: string): Query<DocumentData> | false => {
  if (debug) console.log('getRequestsFromOwnerRef');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const offers = collection(db, 'leasingOffers');
  if (!houseId) return false;
  const q = query(
    offers,
    where('leasingCompanyIds', 'array-contains', tenantId),
    where('hid', '==', hid),
    where('houseId', '==', houseId),
    orderBy('whenString', 'asc'),
  );
  return q;
};

export const getCustomerRelatedRequests = (hid: string): Query<DocumentData> | false => {
  if (debug) console.log('getRequestsFromOwnerRef');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const offers = collection(db, 'leasingOffers');

  const q = query(offers, where('houseId', '==', tenantId), where('hid', '==', hid), orderBy('whenString', 'asc'));
  return q;
};

export const deleteRequest = async (id: string): Promise<boolean> => {
  if (debug) console.log('deleteRequest');
  const offersRef = getRequestsRef();
  if (!offersRef) return false;
  await deleteDoc(doc(offersRef, id));
  return true;
};

export const getRequestsForUserByDateRef = (uid: string, startDate: Moment, endDate: Moment): Query | false => {
  if (!uid || !startDate || !endDate) return false;
  const requestsRef = collection(db, 'leasingOffers');
  if (!requestsRef) return false;
  const q = query(
    requestsRef,
    where('offeredEmployeeIds', 'array-contains', uid),
    where('whenString', '>=', moment(startDate).format('YYYYMMDD')),
    where('whenString', '<=', moment(endDate).format('YYYYMMDD')),
  );
  return q;
};
