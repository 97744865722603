import { checkTenantId, checkUserId } from 'database';
import { iShift } from 'datamodel';
import {
  iLeasingExternalCustomer,
  iLeasingExternalCustomerDepartment,
  iLeasingExternalCustomerOffer,
} from 'datamodel/src/Interfaces/Leasing';
import {
  CollectionReference,
  DocumentData,
  Query,
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db } from 'localFirebase';
import moment, { Moment } from 'moment';

export const getExternalCustomersRef = (): CollectionReference<DocumentData> | false => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const externalCustomersRef = collection(db, 'leasingCompanies', tenantId, 'externalCustomers');
  return externalCustomersRef;
};

export const addExternalCustomer = async (customer: Partial<iLeasingExternalCustomer>): Promise<false | string> => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const externalCustomersRef = getExternalCustomersRef();
  if (!externalCustomersRef) return false;
  const newDoc = await addDoc(externalCustomersRef, {
    ...customer,
    createdAt: Timestamp.now(),
    createdBy: checkUserId(),
  });
  return newDoc.id;
};

export const updateExternalCustomer = async (
  id: string,
  customer: Partial<iLeasingExternalCustomer>,
): Promise<boolean> => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const externalCustomersRef = getExternalCustomersRef();
  if (!externalCustomersRef) return false;
  const externalCustomerDoc = doc(externalCustomersRef, id);
  if (!externalCustomerDoc) return false;
  await updateDoc(externalCustomerDoc, {
    ...customer,
    updatedAt: Timestamp.now(),
    updatedBy: checkUserId(),
  });
  return true;
};

export const addNewExternalCustomerShift = async (id: string, shift: Partial<iShift>): Promise<false | string> => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const externalCustomersRef = getExternalCustomersRef();
  if (!externalCustomersRef) return false;
  const externalCustomerDoc = doc(externalCustomersRef, id);
  if (!externalCustomerDoc) return false;
  const shiftRef = collection(externalCustomerDoc, 'shifts');
  const docId = await addDoc(shiftRef, {
    ...shift,
    createdAt: Timestamp.now(),
    createdBy: checkUserId(),
  });
  return docId.id;
};

export const updateExternalCustomerShift = async (id: string, shift: Partial<iShift>): Promise<boolean> => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const externalCustomersRef = getExternalCustomersRef();
  if (!externalCustomersRef) return false;
  const externalCustomerDoc = doc(externalCustomersRef, id);
  if (!externalCustomerDoc) return false;
  const shiftRef = collection(externalCustomerDoc, 'shifts');
  const shiftDoc = doc(shiftRef, shift.id);
  if (!shiftDoc) return false;
  await updateDoc(shiftDoc, {
    ...shift,
    updatedAt: Timestamp.now(),
    updatedBy: checkUserId(),
  });
  return true;
};

export const deleteExternalCustomerShift = async (id: string, shiftId: string): Promise<boolean> => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const externalCustomersRef = getExternalCustomersRef();
  if (!externalCustomersRef) return false;
  const externalCustomerDoc = doc(externalCustomersRef, id);
  if (!externalCustomerDoc) return false;
  const shiftRef = collection(externalCustomerDoc, 'shifts');
  const shiftDoc = doc(shiftRef, shiftId);
  if (!shiftDoc) return false;
  await deleteDoc(shiftDoc);
  return true;
};

export const getExternalCustomerShifts = async (id: string): Promise<iShift[] | false> => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const externalCustomersRef = getExternalCustomersRef();
  if (!externalCustomersRef) return false;
  const shiftsCollection = collection(externalCustomersRef, id, 'shifts');
  if (!shiftsCollection) return false;
  const shifts: iShift[] = [];
  const docs = await getDocs(shiftsCollection);
  if (docs.empty) return false;
  docs.forEach((shiftDoc) => {
    shifts.push({ ...shiftDoc.data(), id: shiftDoc.id } as iShift);
  });
  return shifts;
};

export const addNewExternalCustomerDepartment = async (
  id: string,
  department: Partial<iLeasingExternalCustomerDepartment>,
): Promise<false | string> => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const externalCustomersRef = getExternalCustomersRef();
  if (!externalCustomersRef) return false;
  const externalCustomerDoc = doc(externalCustomersRef, id);
  if (!externalCustomerDoc) return false;
  const departmentRef = collection(externalCustomerDoc, 'departments');
  const docId = await addDoc(departmentRef, {
    ...department,
    createdAt: Timestamp.now(),
    createdBy: checkUserId(),
  });
  return docId.id;
};

export const updateExternalCustomerDepartment = async (
  id: string,
  department: Partial<iLeasingExternalCustomerDepartment>,
): Promise<boolean> => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const externalCustomersRef = getExternalCustomersRef();
  if (!externalCustomersRef) return false;
  const externalCustomerDoc = doc(externalCustomersRef, id);
  if (!externalCustomerDoc) return false;
  const departmentRef = collection(externalCustomerDoc, 'departments');
  const departmentDoc = doc(departmentRef, department.id);
  if (!departmentDoc) return false;
  await updateDoc(departmentDoc, {
    ...department,
    updatedAt: Timestamp.now(),
    updatedBy: checkUserId(),
  });
  return true;
};

export const getExternalCustomerDepartmentsRef = (id: string): CollectionReference<DocumentData> | false => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const externalCustomersRef = getExternalCustomersRef();
  if (!externalCustomersRef) return false;
  const externalCustomerDoc = doc(externalCustomersRef, id);
  if (!externalCustomerDoc) return false;
  const departmentRef = collection(externalCustomerDoc, 'departments');
  return departmentRef;
};

export const getExternalCustomerById = async (id: string): Promise<iLeasingExternalCustomer | false> => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const externalCustomersRef = getExternalCustomersRef();
  if (!externalCustomersRef) return false;
  const externalCustomerDoc = doc(externalCustomersRef, id);
  if (!externalCustomerDoc) return false;
  const externalCustomer = await getDoc(externalCustomerDoc);
  if (!externalCustomer.exists()) return false;
  return { id: externalCustomer.id, ...externalCustomer.data() } as iLeasingExternalCustomer;
};

export const deleteExternalCustomerDepartment = async (id: string, departmentId: string): Promise<boolean> => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const externalCustomersRef = getExternalCustomersRef();
  if (!externalCustomersRef) return false;
  const externalCustomerDoc = doc(externalCustomersRef, id);
  if (!externalCustomerDoc) return false;
  const departmentRef = collection(externalCustomerDoc, 'departments');
  const departmentDoc = doc(departmentRef, departmentId);
  if (!departmentDoc) return false;
  await deleteDoc(departmentDoc);
  return true;
};

export const getLeasingExternalOffersRef = (): CollectionReference<DocumentData> | false => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const externalCustomersRef = collection(db, 'leasingCompanies', tenantId, 'externalOffers');
  return externalCustomersRef;
};

export const getExternalCustomerOffers = (minDate: string, maxDate: string): Query<DocumentData> | false => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const externalCustomersRef = collection(db, 'leasingCompanies', tenantId, 'externalOffers');
  const q = query(
    externalCustomersRef,
    where('whenString', '>=', minDate),
    where('whenString', '<=', maxDate),
    orderBy('whenString', 'asc'),
  );
  return q;
};

export const addExternalCustomerOffer = async (
  offer: Partial<iLeasingExternalCustomerOffer>,
): Promise<false | string> => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const externalCustomersRef = collection(db, 'leasingCompanies', tenantId, 'externalOffers');
  const newDoc = await addDoc(externalCustomersRef, {
    ...offer,
    createdAt: Timestamp.now(),
  });
  return newDoc.id;
};

export const updateExternalCustomerOffer = async (
  id: string,
  offer: Partial<iLeasingExternalCustomerOffer>,
): Promise<boolean> => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const externalCustomersRef = collection(db, 'leasingCompanies', tenantId, 'externalOffers');
  const offerDoc = doc(externalCustomersRef, id);
  if (!offerDoc) return false;
  await updateDoc(offerDoc, {
    ...offer,
    updatedAt: Timestamp.now(),
    updatedBy: checkUserId(),
  });
  return true;
};

export const getExternalOffersForUserByDateRef = (uid: string, startDate: Moment, endDate: Moment): Query | false => {
  if (!uid || !startDate || !endDate) return false;
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const externalOffersRef = collection(db, 'leasingCompanies', tenantId, 'externalOffers');
  if (!externalOffersRef) return false;
  const q = query(
    externalOffersRef,
    where('whenString', '>=', moment(startDate).format('YYYYMMDD')),
    where('whenString', '<=', moment(endDate).format('YYYYMMDD')),
    where('offeredEmployee.id', '==', uid),
    where('status', '!=', 'cancelled'),
  );
  return q;
};
